/**
 * @author Irakli Kandelaki
 */

import { useApartmentStore } from "@/store/pinia/apartments";
import type { Ref } from "vue";
import { computed } from "vue";
import { RouteLocation } from "vue-router";

/**
 * @summary Check render type by route params.
 */

export default (route: RouteLocation): Ref<string> => {
    const apStore = useApartmentStore();
    const svgRenderType: Ref<string> = computed((): string => {
        if (apStore.isApartmentOpenedFromRow) return apStore.svgRenderType;
        const pathArray: string[] = [];
        for (let i in route?.params) {
            let param = route.params[i] as string;

            if (!param) break;

            pathArray.push(param);
        }

        if (pathArray.length === 2 && pathArray[0].includes('projects')) {
            return 'project';
        } else if (pathArray[pathArray.length - 1].includes('block')) {
            return 'block';
        } else if (pathArray[pathArray.length - 1].includes('floor') ||
            pathArray[pathArray.length - 1].includes('apartment')) {
            return 'floor';
        }

    })
    apStore.svgRenderType = svgRenderType.value;
    return svgRenderType;
}

